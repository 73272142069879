import React from 'react';
import { FaBars } from 'react-icons/fa';
import { NavLink as Link } from 'react-router-dom';

const Nav = ({ children }) => (
  <nav className="bg-gray-800 h-20 flex justify-between px-4 sm:px-8 z-12">
    {children}
  </nav>
);

const NavLink = ({ to, children, activeClassName }) => (
  <Link
    to={to}
    className="text-white-500 title-font text-white font-medium flex items-center no-underline px-4 h-full cursor-pointer focus:text-teal-500 focus:bg-gray-900 hover:text-teal-500"
  >
    {children}
  </Link>
);

const HomeLink = ({ to, children, activeClassName }) => (
    <Link
      to={to}
      className="text-white-500 text-xl title-font text-white font-medium flex items-center no-underline px-4 h-full cursor-pointer focus:text-teal-500 focus:bg-gray-900 hover:text-teal-500"
    >
      {children}
    </Link>
  );

const Bars = () => (
  <FaBars className="hidden text-gray-500 sm:block absolute top-0 right-0 transform translate-x-full text-2xl cursor-pointer" />
);

const NavMenu = ({ children }) => (
  <div className="flex items-center mr-[-24px] md:sticky sm:mr-[24px]">
    {children}
  </div>
);

export { Nav, NavLink, Bars, NavMenu, HomeLink};