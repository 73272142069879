// skillData.js

const hardwareSkills = ["Rapid Prototyping", "Machining", "Hand Tools", "3D Printing", 
                        "Arduino", "Soldering", "Laser/Water-Jet Cutting", 
                        "Drilling", "Milling", "Test Fixture Assembly"];

const softwareSkills = ["SolidWorks", "CATIA", "AutoDesk Fusion360","COMSOL Multiphysics",
                        "Abacus","Ansys", "Python", "C++","JavaScript", "MATLAB", "Microsoft Office", 
                        "Simulink"];

export {hardwareSkills}
export {softwareSkills}




