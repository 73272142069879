import React from "react";
import { SocialIcon } from 'react-social-icons'
import { Link } from 'react-router-dom'

export default function About() {
    return (
        <section id="About" className="text-gray-400 bg-gray-900 body-font">
            <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center">
                <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                    <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
                    Hi, I'm Sohun.
                    </h1>
                    <p className="mb-8 leading-relaxed">
                    Welcome to my self-created portfolio website! As a current graduate mechanical engineering 
                    student at Stanford University, I bring a unique blend of academic rigor and hands-on 
                    experience, coupled with a quick-thinking and proactive approach to 
                    problem-solving. I am experienced in mechanical design, especially for 
                    thermostructural applications, and am also passionate about autonomous
                    and optimal control of dynamic systems. 
                    </p>
                    <div className="flex justify-center mb-12">
                    <Link
                        to="/contact"
                        className="inline-flex text-white bg-teal-500 border-0 py-2 px-6 focus:outline-none hover:bg-teal-600 rounded text-lg"
                        >
                        Contact Me
                    </Link>
                    <Link
                        to="/projects"
                        className="ml-4 inline-flex text-gray-400 bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-lg">
                        See My Past Projects
                    </Link>
                    <SocialIcon url = "https://www.linkedin.com/in/sohunpatel/" className = "hover:animate-pulse ml-8" />
                    </div>
                    <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
                    About Me
                    </h1>
                    <p className="mb-4 leading-relaxed">
                    A Vacaville, California native passionate about changing the world through engineering brilliance, 
                    I ventured to southern California in 2019 to pursue mechanical engineering at UCLA. During my time there, 
                    I gained practical engineering experience through student-run organizations, including the American Society of Mechanical Engineers (ASME), 
                    Bruin Formula Racing (BFR), and the Biomedical Engineering Society (BMES). I also conducted research in Dr. Nasr Ghoniem's laboratory on 
                    materials in extreme environments throughout my time at UCLA, graduating with my BS in mechanical engineering in 2023.
                    </p>
                    <p className="mb-6 leading-relaxed">
                    Since Fall 2023, I have been pursuing an MS in Mechanical Engineering at Stanford University, focusing on
                    Automatic Controls and Robotics, as I enjoy connecting software with hardware. I'm excited 
                    to graduate in April 2025. Between my time at UCLA and Stanford, I interned at Tesla 
                    as a Mechanical Design Engineering Intern with the Vehicular Thermal Engineering team, where I honed my 
                    skills in innovative design for manufacturing.
                    </p>
                    <div className="flex justify-center w-3/4">
                    <img
                        className="object-cover object-center rounded w-1/4"
                        alt="hero"
                        src="./stanford.webp"
                    />
                    <img
                        className="object-cover object-center rounded w-1/2"
                        alt="hero"
                        src="./ucla.png"
                    />
                    <img
                        className="object-cover object-center rounded w-1/4"
                        alt="hero"
                        src="./tesla.png"
                    />
                    </div>
                </div>

            <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                <img
                    className="object-cover object-center rounded"
                    alt="hero"
                    src="./ProfilePic.JPG"
                />
            </div>
            </div>
      </section>
    )
}