import React from "react";
import { Nav, NavLink, NavMenu, HomeLink } from "./NavbarElements";
 
const Navbar = () => {
    return (
        <Nav className="sticky">
            <NavMenu>
                <HomeLink to="/" activeStyle className="text-2xl">
                    Sohun Patel
                </HomeLink>
                <NavLink to="/skills" activeStyle>
                    Skills
                </NavLink>
                <NavLink to="/projects" activeStyle>
                    Projects
                </NavLink>
                <NavLink to="/contact" activeStyle>
                    Contact
                </NavLink>
            </NavMenu>
        </Nav>
    );
};
 
export default Navbar;