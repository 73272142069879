import React from "react";
import ReactPlayer from 'react-player';
import {WrenchScrewdriverIcon} from "@heroicons/react/24/solid"




export default function Projects() {
    return(
        <>
        <section id="Projects" className="text-gray-400 bg-gray-900 body-font">
            <div className="container mx-auto flex px-1 py-20 items-center text-center flex-col lg:px-20">
                <div className="flex flex-col w-full">
                    <WrenchScrewdriverIcon className="mx-auto inline-block w-1/4 mb-8" />
                    <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-white">
                        Projects
                    </h1>
                </div>
                <div className="flex justify-center mb-20">
                    <a
                        href="#HapticVest"
                        className="infline-flex text-white bg-blue-500 border-0 py-2 px-6 focus:outline-none hover:bg-blue-600 rounded text-lg font-bold">
                        Posture-Correcting Vest
                    </a>                    
                    <a
                        href="#Prosthetic"
                        className="ml-4 infline-flex text-white bg-sky-500 border-0 py-2 px-6 focus:outline-none hover:bg-sky-600 rounded text-lg font-bold">
                        Prosthetic Hand
                    </a>
                    <a
                        href="#BruinBot"
                        className="ml-4 inline-flex text-white bg-cyan-500 border-0 py-2 px-6 focus:outline-none hover:bg-cyan-600 rounded text-lg font-bold">
                        BruinBot
                    </a>
                    <a
                        href="#Helmet"
                        className="ml-4 inline-flex text-white bg-teal-500 border-0 py-2 px-6 focus:outline-none hover:bg-teal-600 rounded text-lg font-bold">
                        Concussion-Detecting Helmet
                    </a>
                    <a
                        href="#Rocket"
                        className="ml-4 inline-flex text-white bg-emerald-500 border-0 py-2 px-6 focus:outline-none hover:bg-emerald-600 rounded text-lg font-bold">
                        High-Powered Rocket 
                    </a>
                </div>

                <section id="HapticVest" className="text-gray-400 bg-blue-500 border-4 border-white body-font rounded font-bold w-full mb-20">
                    <div className="pl-4 pt-4 mb-4 text-left">
                        <h1 className="title-font sm:text-4xl text-3xl font-medium text-white">
                            Haptic Vest for Posture Alignment
                        </h1>
                        <div className="flex items-center justify-center mt-4">
                            <img
                                className="object-cover object-center rounded w-1/2 border-4 border-black rounded mt-4 justify-center"
                                alt="hero"
                                src="./HapticVest/Explaining.jpeg"
                            />
                        </div>
                    </div>
                    <div className="container mx-auto flex px-4 py-10 md:flex-row items-start">
                        <div className="lg:flex-grow md:w-full lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-0 md:mb-0 items-center text-center">
                            <p className="mb-8 leading-relaxed text-white w-full">
                            In this Stanford ME 327 course final project, my group and I developed a posture alignment 
                            vest that helps users correct their posture through haptic feedback. Our goal was to explore
                            how effective we could be in using minimal haptic feedback to nudge users towards a healthy 
                            posture throughout their day. Using two Inertial Measurement Units (IMUs) and two vibration motors, 
                            the vest provides haptic feedback proportional to the spinal column bending angle and the chest 
                            angle with respect to the floor. The lightweight, vibration-based vest effectively alerts users 
                            when their posture is out of alignment and guides them towards a better position using intuitive 
                            spring-based mechanics.
                            </p>
                            <p className="mb-8 leading-relaxed text-white w-full">
                            For the posture measurement, we placed 2 IMUs on the upper and lower back where we measure 
                            the forward and backward pitch, or tilt, of the sensors to determine the state of a user’s posture. 
                            We define poor posture as occurring in two ways:
                                1. Upper back is curved relative to the lower back and does not maintain the same angle (back is not flat).
                                2. Upper back is bent too far forward about the hip, even if the back is straight.
                            </p>
                            <p className="mb-8 leading-relaxed text-white w-full">
                            Haptic feedback is provided to the user via two eccentric rotating mass (ERM) motors. One is placed on 
                            the front of the vest near the user’s chest, and the other is placed on the vest near the upper back of 
                            the user. If the user’s upper back is curved too far forward relative to the lower back, the front ERM is 
                            activated. The amplitude of its vibration is proportional to the magnitude of the relative difference 
                            between the upper and lower back angles, once this difference is larger than the minimum threshold. A similar 
                            proportional vibration feedback is applied to the back ERM for the case when the upper back is curved too far 
                            backward relative to the lower back. When the user’s back is bent forward past the absolute limit, both ERMs 
                            activate and buzz at the largest amplitude until the user corrects their tilt to fall below the maximum tilt 
                            threshold.
                            </p>
                            <p className="mb-8 leading-relaxed text-white w-full">
                            We use an Arduino Uno to gather live sensor data through I2C communication protocol. The Arduino sends PWM signals 
                            to the ERMs to induce vibrations at the specified amplitude when required. To keep the system portable, the Arduino 
                            is powered by a 9V battery through its barrel jack port. We also use a protoboard to relay 
                            5V power, ground, and the SCL, and SDA lines across our IMUs and ERMs with reliable soldered connections. Our electronics 
                            are integrated into a wearable and adjustable vest. The vest is custom-sewn and includes pockets to keep the IMUs, ERMs, 
                            Arduino, battery, and protoboard secure during operation. Based on the user’s back length, the upper IMU’s position is adjustable 
                            through velcro straps. To keep a neat appearance, the vest also allows for proper cable management between the IMUs and the Arduino.
                            </p>
                            <p className="mb-8 leading-relaxed text-white w-full">
                            My personal contributions to this project include researching compatible sensors and sourcing the IMUs, programming an 
                            Arduino Uno to read live pitch angle readings from the IMUs and to then send the appropriate signals to the ERMs to 
                            dynamically alert the user of poor posture, and also devising the electronic layout between the Arduino Uno, the 9V battery,
                            the IMUs, and the ERMs.
                            </p>
                        </div>
                        <div className="lg:max-w-lg lg:w-1/2 md:w-1/2 w-full flex flex-col">
                            <img
                                className="object-cover object-center rounded mb-4 border-4 border-black "
                                alt="hero"
                                src="./HapticVest/VestFront.jpeg"
                            />
                             <img
                                className="object-cover object-center rounded mb-4 border-4 border-black "
                                alt="hero"
                                src="./HapticVest/VestBack.jpeg"
                            />
                             <img
                                className="object-cover object-center rounded mb-4 border-4 border-black "
                                alt="hero"
                                src="./HapticVest/ERMResponse.jpeg"
                            />                                                              
                        </div>
                    </div>
                </section>


                <section id="Prosthetic" className="text-gray-400 bg-sky-500 border-4 border-white body-font rounded font-bold w-full mb-20">
                    <div className="pl-4 pt-4 mb-4 text-left">
                        <h1 className="title-font sm:text-4xl text-3xl font-medium text-white">
                            Prosthetic Hand for Poland Syndrome
                        </h1>
                        <div className="flex items-center justify-center mt-4">
                            <img
                                className="object-cover object-center rounded w-1/2 border-4 border-black rounded mt-4 justify-center"
                                alt="hero"
                                src="./Prosthetic/complete-assembly.png"
                            />
                        </div>
                    </div>
                    <div className="container mx-auto flex px-4 py-10 md:flex-row items-start">
                        <div className="lg:flex-grow md:w-full lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-0 md:mb-0 items-center text-center">
                            <p className="mb-8 leading-relaxed text-white w-full">
                                For my senior capstone project at UCLA, my group and I designed an
                                electro-mechanical prosthetic hand prototype for a Poland Syndrome patient. 
                                The patient was born with three undeveloped fingers and hoped to use the prosthetic
                                to help him drink out of his 40 oz. HydroFlask bottle.
                            </p>
                            <p className="mb-8 leading-relaxed text-white w-full">
                                As a team of four mechanical engineers, we wanted our prototype to be 
                                comfortable, yet cost-efficient, as we were given a budget of $600. 
                                Thus, we decided to 3D-print as much of the prosthetic as we could,
                                designing around the patient's three underdeveloped fingers. We 
                                also wanted to learn and implement electronic control, so we sourced 
                                various components such as high-torque DC motors, an Arduino board, and 
                                motor controllers.
                            </p>

                            <p className="mb-8 leading-relaxed text-white w-full">
                                To help us design around the patient, I set up an appointment with a 
                                Lux Lab on UCLA's campus. With the help of their 3D-scanners, we obtained 
                                a digital 3D-rendering of the patient's hand. I imported this file into
                                SolidWorks and began designing the prosthetic. After many iterations of
                                modeling, 3D-printing, and testing I finally obtained a comfortable, functional
                                design. The prosthetic is in the shape of a wide finger, or paddle. It has three
                                parts, which are hinged to each other via dowel pins. I decided to print the 
                                top two pieces out of PLA plastic and the bottom piece printed out of a flexible
                                TPU filament, since the patient's hand slides into this piece.
                            </p>

                            <p className="mb-8 leading-relaxed text-white w-full">
                                The paddle's curling motion is activated by a string-tensioning mechanism. 
                                Two DC motors mounted to the patient's wrist each drive their own pulley, which 
                                tensions the fishing line running through the bottom of the paddle. The motors 
                                are driven when the patient presses down on a force-sensitive resistor located
                                inside the bottom paddle piece. There are three elastic ropes along the topside
                                of the paddle that flex the paddle back into its straight form when the motors 
                                are not driven. I designed channels in the paddle that allowed for the fishing line 
                                and elastic rope to be easily routed through them. 
                            </p>

                            <p className="mb-8 leading-relaxed text-white w-full">
                                Besides modeling the prosthetic, I also planned out the electrical system of the
                                prosthetic. I sourced all components and created a detailed schematic of how all
                                the electrical parts interact each other. I also programmed the Arduino to read 
                                the force-sensing resistor's output and correspondingly drive the DC motors via I2C 
                                control. 
                            </p>

                            <h2 className="text-2xl mb-8 leading-relaxed text-black w-full">
                                Watch the fun video below to see more of my team's progress!
                            </h2>
                            <div className = "w-full justify-center">
                                <div className ="w-full mx-auto aspect-w-16 aspect-h-9">
                                    <ReactPlayer
                                        url="https://youtu.be/pE9Om_kQ8yY"
                                        controls
                                        width="100%"
                                        height="auto"
                                        className="rounded-lg shadow-lg"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="lg:max-w-lg lg:w-1/2 md:w-1/2 w-full flex flex-col">
                            <img
                                className="object-cover object-center rounded mb-4 border-4 border-black "
                                alt="hero"
                                src="./Prosthetic/hand-scan.png"
                            />
                            <div className="w-full flex flex-row mb-4 rounded">
                                <img
                                    className="object-cover object-center rounded w-1/2 border-4 border-black "
                                    alt="hero"
                                    src="./Prosthetic/paddle-cad.png"
                                />
                                <img
                                    className="object-cover object-center rounded w-1/2 ml-4 border-4 border-black "
                                    alt="hero"
                                    src="./Prosthetic/channels.png"
                                />                       
                            </div>
                            <img
                                className="object-cover object-center rounded w-full border-4 border-black rounded mb-4"
                                alt="hero"
                                src="./Prosthetic/paddle-mount.png"
                            />
                            <img
                                className="object-cover object-center rounded w-full border-4 border-black rounded mb-4"
                                alt="hero"
                                src="./Prosthetic/reality.jpeg"
                            />
                            <img
                                className="object-cover object-center rounded w-full border-4 border-black rounded"
                                alt="hero"
                                src="./Prosthetic/schematic.png"
                            />
                        </div>
                    </div>
                </section>

                <section id="BruinBot" className="text-gray-400 bg-cyan-500 border-4 border-white body-font rounded font-bold w-full mb-20">
                    <div className="pl-4 pt-4 mb-4 text-left">
                        <h1 className="title-font sm:text-4xl text-3xl font-medium text-white">
                            BruinBot
                        </h1>
                        <div className="flex items-center justify-center mt-4">
                            <img
                                className="object-cover object-center rounded w-1/2 rounded mt-4 justify-center"
                                alt="hero"
                                src="./BruinBot/complete-render.png"
                            />
                        </div>
                    </div>
                    <div className="container mx-auto flex px-4 py-10 md:flex-col items-start">
                        <div className="lg:flex-grow md:w-full lg:pr-0 md:pr-0 flex flex-col md:items-start md:text-left mb-10 items-center text-center">
                            <p className="mb-8 leading-relaxed text-white w-full">
                                BruinBot is UCLA's interactive robotic companion that strolls around campus,
                                spreads cheer, and dispenses snacks. It was designed by X1 Robotics, which 
                                is a student-led project team that is a part of the American Society of Mechanical 
                                Engineers (ASME) club on campus. While the complete design and assembly of the 
                                robot lasted three years, I joined during the final year, in which the robot's 
                                assembly was the biggest priority. This was during the 2021-2022 school-year, 
                                which was my third year at UCLA. 
                            </p>
                            <p className="mb-8 leading-relaxed text-white w-full">
                                I joined the circuits/controls subteam, and our main task was to plan out and assemble
                                the electrical "nervous" system of the robot. This included powering, mounting, and testing 
                                each electrical component. The testing process also included programming the various Arduino 
                                microcontrollers in the robot to control their respective motor systems so that the proper
                                functionality of the robot could be achieved. 
                            </p>

                            <p className="mb-8 leading-relaxed text-white w-full">
                                The electrical system consisted of 10 different motors, 6 micro-controllers, a LIDAR sensor,
                                a camera, and a microphone. With so many components, it was easy for the team to lose track
                                of each one's location in the robot and how they all communicated and connected with each 
                                other. To overcome this issue, I created a visual schematic on Adobe Illustrator that we 
                                could easily reference. The diagram placed each component in its approximate location and 
                                demonstrated how to connect each component to batteries for power and also to other components 
                                for communication. This organized schematic saved us a lot of time when it came to the physical 
                                assembly of the system and showed us that we would need other components such as power distribution
                                boards and Buck converters as well. 
                            </p>

                            <p className="mb-8 leading-relaxed text-white w-full">
                                I also programmed the head Arduino, connected to a dual L298N stepper motor driver, to drive the 
                                two NEMA 17 motors responsible for the nodding and swiveling motion of BruinBot's head. For each motion, 
                                I implemented limit switches that allowed the Arduino to determine the starting, or home, position of the 
                                robot head and move the head to this "home" position on robot startup. The Arduino also takes Serial input 
                                commands from the main Raspberry Pi board that tell it when to nod or swivel the head.
                            </p>

                            <p className="mb-8 leading-relaxed text-white w-full">
                                Besides these two tasks, I programmed the drivetrain Arduino to allow manual driving of the robot with
                                a joystick. While assembling the electrical system, I learned many important electrical skills such as
                                soldering, wire splicing, and applying heat shrink for secure electric connections.
                            </p>



                            <h2 className="text-2xl mb-8 leading-relaxed text-black w-full">
                                View BruinBot's complete technical report below!
                            </h2>
                            <div className = "w-full justify-center">
                                <div>
                                    <iframe title = "Google Doc: BruinBot Technical Report" src={"https://docs.google.com/document/d/1sNYMO2Ey9azyBHMDh1D0FDDlSicSV8PXN3IYhADpZmo/edit?usp=sharing"} width="100%" height="600px" />
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex flex-col justify-center">
                            <div className = "flex flex-row justify-center mb-8">
                                <img
                                    className="object-cover object-center rounded mb-4 mr-4 border-4 border-black w-1/2"
                                    alt="hero"
                                    src="./BruinBot/complete-reality.jpeg"
                                />
                                <img
                                    className="object-cover object-center rounded mb-4 border-4 border-black w-1/2"
                                    alt="hero"
                                    src="./BruinBot/wiring-schematic.png"
                                />
                            </div>
                            <div className = "flex flex-row justify-center mb-8">
                                <img
                                    className="object-cover object-center rounded mb-4 mr-4 border-4 border-black w-1/2"
                                    alt="hero"
                                    src="./BruinBot/nodding.gif"
                                />
                                <img
                                    className="object-cover object-center rounded mb-4 border-4 border-black w-1/2"
                                    alt="hero"
                                    src="./BruinBot/swiveling.gif"
                                />
                            </div>
                            <div className = "flex flex-row justify-center mb-8">
                                <img
                                    className="object-cover object-center rounded mb-4 mr-4 border-4 border-black w-1/5"
                                    alt="hero"
                                    src="./BruinBot/joystick-drive.gif"
                                />
                                <img
                                    className="object-cover object-center rounded mb-4 border-4 border-black w-3/4"
                                    alt="hero"
                                    src="./BruinBot/driving.gif"
                                />
                            </div>
                            <div className = "flex flex-row justify-center">
                                <img
                                    className="object-cover object-center rounded border-4 border-black"
                                    alt="hero"
                                    src="./BruinBot/mounting.jpeg"
                                />
                            </div>
                        </div>
                    </div>
                    </section>

                    <section id="Helmet" className="text-gray-400 bg-teal-500 border-4 border-white body-font rounded font-bold w-full mb-20">
                    <div className="pl-4 pt-4 mb-4 text-left">
                        <h1 className="title-font sm:text-4xl text-3xl font-medium text-white">
                            Concussion-Detecting Helmet
                        </h1>
                        <div className="flex items-center justify-center mt-4">
                            <img
                                className="object-cover object-center rounded w-1/2 border-4 border-black rounded mt-4 justify-center"
                                alt="hero"
                                src="./Concussion/helmet.png"
                            />
                        </div>
                    </div>
                    <div className="container mx-auto flex px-4 py-10 md:flex-col items-start">
                        <div className="lg:flex-grow md:w-full lg:pr-0 md:pr-0 flex flex-col md:items-start md:text-left mb-10 items-center text-center">
                            <p className="mb-8 leading-relaxed text-white w-full">
                                As a former baseball player and a passionate specator of many sports, I am passionate about creating 
                                technology to monitor and keep athletes safe. During my second year at UCLA (2020-2021 school-year), I 
                                joined the Design Team of the Biomedical Society Society (BMES) at UCLA, which was working 
                                on a concussion-cetecting helmet as its technical project for the year. Because of the 
                                COVID-19 pandemic, this project was completely remote. I joined the computer-aided design (CAD) subteam with 
                                two other talented mechanical engineering students. Our goal was to create a portable helmet design  
                                suitable for use during football games and capable of holding all components of the Electroencephalogram (EEG) 
                                constructed by the circuitry subteam. We first constructed a prototype design by developing a test headband for 
                                circuitry team to work with for EEG development and testing. This headband consisted of a 3D-printed compartment to 
                                hold an Arduino and also adjustable, 3D-printed clips that secured the EEG electrodes to the head. Then, we 
                                integrated a similar design into a standard football helmet and generated a final CAD model.
                            </p>
                            <p className="mb-8 leading-relaxed text-white w-full">
                                Because this project was designed remotely, we were unable to test the product on an actual
                                athlete. Nevertheless, through design iteration, I was able to improve my CAD skills in SolidWorks and 
                                learn how to design for 3D-printing. My main tasks included sketching an initial design concept, 
                                designing the electrode-holder clips, and also designing the PCB holder for the actual helmet integration.
                            </p>
                        </div>
                        <div className="pl-4 pt-4 mb-4 text-left">
                            <h2 className="text-2xl leading-relaxed text-black w-full">
                                Prototype Headband
                            </h2>
                        </div>
                        <div className="w-full flex flex-col justify-center">
                            <div className = "flex flex-row justify-center mb-4">
                                <img
                                    className="object-cover object-center rounded border-4 border-black"
                                    alt="hero"
                                    src="./Concussion/prototype-schematic.png"
                                />
                            </div>
                            <div className = "flex flex-row justify-center mb-4">
                                <img
                                    className="object-cover object-center rounded mr-4 border-4 border-black"
                                    alt="hero"
                                    src="./Concussion/electrode-holder.png"
                                />
                                <img
                                    className="object-cover object-center rounded border-4 border-black"
                                    alt="hero"
                                    src="./Concussion/arduino-mount.png"
                                />
                            </div>
                            <div className="flex flex-row justify-center mb-8">
                                <img
                                    className="object-cover object-center rounded mb-4 mr-4 border-4 border-black"
                                    alt="hero"
                                    src="./Concussion/prototype-reality.png"
                                />
                            </div>
                        <div className="pl-4 pt-4 mb-4 text-left">
                            <h2 className="text-2xl mb-0 leading-relaxed text-black w-full">
                                Helmet Integration
                            </h2>
                        </div>
                        <div className="w-full flex flex-col justify-center">
                            <div className = "flex flex-row justify-center mb-4">
                                <img
                                    className="object-cover object-center mr-4 rounded border-4 border-black"
                                    alt="hero"
                                    src="./Concussion/helmet-integration.png"
                                />
                                <img
                                    className="object-cover object-center rounded border-4 border-black"
                                    alt="hero"
                                    src="./Concussion/player-headband.png"
                                />
                            </div>
                            <div className = "flex flex-row justify-center mb-4">
                                <img
                                    className="object-cover object-center rounded mr-4 border-4 border-black"
                                    alt="hero"
                                    src="./Concussion/head-electrode-holder.png"
                                />
                                <img
                                    className="object-cover object-center rounded border-4 border-black"
                                    alt="hero"
                                    src="./Concussion/ear-electrode-holder.png"
                                />
                            </div>
                            <div className = "flex flex-row justify-center">
                                <img
                                    className="object-cover object-center rounded mr-4 border-4 border-black w-1/2"
                                    alt="hero"
                                    src="./Concussion/pcb-holder-clip.png"
                                />
                                <img
                                    className="object-cover object-center rounded border-4 border-black w-1/2"
                                    alt="hero"
                                    src="./Concussion/pcb-holder.png"
                                />
                            </div>
                        </div>
                    </div>
                    </div>
                    </section>

                    <section id="Rocket" className="text-gray-400 bg-emerald-500 border-4 border-white body-font rounded font-bold w-full">
                    <div className="pl-4 pt-4 mb-4 text-left">
                        <h1 className="title-font sm:text-4xl text-3xl font-medium text-white">
                            High-Powered Rocket
                        </h1>
                        <div className="flex items-center justify-center mt-4">
                            <img
                                className="object-cover object-center rounded w-1/2 border-4 border-black rounded mt-4 justify-center"
                                alt="hero"
                                src="./Rocket/me-rocket.jpeg"
                            />
                        </div>
                    </div>
                    <div className="container mx-auto flex pl-4 pr-0 py-10 md:flex-row items-start">
                        <div className="lg:flex-grow md:w-full lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-0 md:mb-0 items-center text-center">
                            <p className="mb-8 leading-relaxed text-white w-full">
                                For the Engineering 96R class final project during the Fall quarter of my first year (2019-2020) at UCLA, 
                                I teamed up with a team of 3 other students to design, build, and launch a high-Powered rocket in the 
                                Mojave Desert. Our rocket, aka "Bullet Bill," reached the target apogee of 2750 ft, successfully deployed its 
                                parachute, and safely transported an egg payload back down to the ground! Before the launch, we gave a design
                                review to our professor and mentors.
                            </p>
                            <p className="mb-8 leading-relaxed text-white w-full">
                                My contributions towards the rocket included designing the subsonic nose cone on SolidWorks and printing 
                                the subsonic nose cone with its conic shape. I also re-inforced the nose cone with fiberglass. 
                                For fin retainment, I designed and laser-cut the thrust bulkheads, centering rings, and fins of the rocket 
                                as well. To secure the egg in place and keep it safe during the launch, I surrounded it in bubble wrap and 
                                placed it on a bed of packing peanuts in the region between the upper body tube and nose cone.
                            </p>
                            <div className = "flex flex-row justify-center mb-4">
                                <video
                                    className="rounded border-4 border-black"
                                    controls
                                    muted>
                                    <source src="./Rocket/launch-vid.mp4" type="video/mp4"></source>
                                </video>
                            </div>
                        </div>
                        <div className="lg:max-w-lg lg:w-1/2 md:w-1/2 w-full flex flex-col">
                            <div className = "flex flex-row justify-center mb-4">
                                <img
                                    className="object-cover object-center rounded mr-4 border-4 border-black"
                                    alt="hero"
                                    src="./Rocket/rocket-on-rails.jpeg"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="container mx-auto flex pl-4 pr-0 py-10 md:flex-col items-start">
                        <div className="flex flex-col justify-center">
                            <div className = "flex flex-row justify-center mb-4">
                                <img
                                    className="object-cover object-center mr-4 rounded border-4 border-black w-1/2"
                                    alt="hero"
                                    src="./Rocket/nose-cone-cad.png"
                                />
                                <img
                                    className="object-cover object-center rounded border-4 border-black w-2/5"
                                    alt="hero"
                                    src="./Rocket/nose-cone-reality.jpeg"
                                />
                            </div>
                            <div className = "flex flex-row justify-center mb-4">
                                <img
                                    className="object-cover object-center rounded mr-4 border-4 border-black w-1/2"
                                    alt="hero"
                                    src="./Rocket/fins-setup.jpeg"
                                />
                                <img
                                    className="object-cover object-center rounded border-4 border-black w-2/5"
                                    alt="hero"
                                    src="./Rocket/final-fins.jpeg"
                                />
                            </div>
                            <div className = "flex flex-row justify-center">
                                <img
                                    className="object-cover object-center rounded mr-4 border-4 border-black w-2/5"
                                    alt="hero"
                                    src="./Rocket/egg-wrap.jpeg"
                                />
                                <img
                                    className="object-cover object-center rounded border-4 border-black w-1/2"
                                    alt="hero"
                                    src="./Rocket/bubble-wrap.jpeg"
                                />
                            </div>
                        </div>
                    </div>
                    </section>
            </div>
        </section>
        </>
    )
}
