import React from "react";

import Navbar from "./components/Navbar";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Projects from "./pages/Projects";
import Skills from "./pages/Skills";

import './App.css';

function App() {
  const appStyle = {
    backgroundColor: '#111827', // Replace with your desired color
    minHeight: '100vh', // Ensure the background covers the entire viewport height
  };
  return (
    <div style={appStyle}>
      <Router>
        <Navbar />
          <Routes>
            <Route exact path="/" element={<About />} />
            <Route
              path="/contact"
              element={<Contact />}
            />
            <Route path="/skills" element={<Skills />} />
            <Route
              path="/projects"
              element={<Projects />}
            />
          </Routes>
      </Router>
      </div>
  );
}

export default App
